<!-- 线索转入 -->
<template>
  <div class="leadChangeInto">
    <div class="title">线索转入申请</div>
    <van-search
      v-model="searchStr"
      show-action
      placeholder="请输入完整的电话号码查询申请转入的线索"
      @search="onSearch">
      <template #action>
        <img class="img" :src="searchIcon" @click="onSearch"/>
      </template>
    </van-search>
    <div class="leadsInfo">
      <div v-for="(item, index) in leadList" :key="index" class="lead-item common-block">
        <div class="customerInfo">
          <div class="phone">{{ item.userMobile }}</div>
          <div class="name">{{ item.userName }}</div>
        </div>
        <div class="shop">
          <span>{{ item.dealerName }}</span>
          <span v-if="item.dealerId !== userInfo?.dealers[0]?.id" class="changeInto" @click="changeInto(item)">申请转入</span>
          <!-- <span class="changeInto" @click="changeInto(item)">申请转入</span> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
import searchIcon from '@/images/search_icon.svg'
import leadChangeIntoServices from '@/services/leadChangeIntoServices'
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
export default {
  name: 'LeadChangeInto',
  components: {
    SearchBarNew,
  },
  data() {
    return {
      searchIcon,
      searchStr: '',
      leadList: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {},
  methods: {
    // 查询需要转入的线索
    async onSearch() {
      const str = /^1[3456789]\d{9}$/
      if (!str.test(this.searchStr)) {
        this.$toast('请输入完整且正确的手机号')
        return false 
      }
      this.leadList = []
      loading.showLoading()
      const result = await leadChangeIntoServices.getDealerTransferList({ userMobile: this.searchStr })
      loading.hideLoading()
      this.leadList = result
    },
    // 申请转入
    changeInto(item) {
      // 判断自己店是否有当前手机号所关联线索
      const { id, userName, userMobile, dealerName } = item
      if (this.leadList.some(({ dealerId }) => { return dealerId === this.userInfo?.dealers[0]?.id })) {
        this.$toast('店内已存在同号码线索正在跟进，无需申请转入！')
        return false
      }
      this.$router.push({
        path: '/changeInto',
        query: {
          id,
          userName,
          userMobile,
          orgDealerName: dealerName
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.leadChangeInto{
  font-size: 13px;
  color: @black;
  .title{
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    font-size: 14px;
    background: #fff;
  }
  /deep/.van-search{
    .van-search__content{
      border-radius: 20px;
    }
    .van-search__action{
      display: flex;
      align-items: center;
      img{
        width: 22px;
      }
    }
  }
  .leadsInfo{
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    .lead-item{
      padding: 16px 12px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      .customerInfo{
        margin-bottom: 8px;
        font-size: 16px;
        display: flex;
        .phone{
          width: 120px;
        }
        .name{
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .shop{
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        .changeInto{
          color: @yellow-text;
          font-size: 14px;
        }
      }
    }
  }
}
</style>