import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

const leadChangeIntoServices = {
  /**
   * 根据手机号查询可转入线索
  */
  async getDealerTransferList(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/query`, { params }).then(thenResolve)
  },
  /**
   * 提交转入申请
   */
  async dealerTransferApply(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/apply`, params).then(thenResolve)
  },
  /**
   * 查询申请详情
   */
  async dealerTransferDetail(params) {
    return await request.get(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/detail`, { params }).then(thenResolve)
  },
  /**
   * 审核
   */
  async dealerTransferReview(params) {
    return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/dealerTransfer/review`, params).then(thenResolve)
  },
}
export default leadChangeIntoServices
